export enum RequestType {
  GET_ANNUITIES_FOR_AMOUNT, GET_ANNUITY_FOR_AMOUNT, GET_DEFAULT_TERMS, GET_SUPPORTED_COUNTRIES,
  GET_PARTNER_SHOPS, EMAIL_DOMAIN_TLS_CHECK,

  BASE_PURCHASE_INFO, CREATE_SESSION, URL_REFERRAL_INIT, GET_ANNUITIES,
  STORE_DATA, VERIFIED_SESSION_INFO, TRIGGER_FINTEC_PRE_ACCOUNT, RISK_CHECK, SET_PREFERRED_ANNUITY,
  ONLINEIDENTIFICATION_GET_PREFERRED_PROVIDER,

  URL_REFERRAL_TOPUP,

  REPLACE_TOKEN, LOGOUT,

  TRIGGER_IDENT, CHANGE_PREFERRED_ANNUITY, GET_UNUSED_URL_INFO, SELECT_TRANSACTION, RESEND_IDENT_SMS,

  LIST_CONVERSATIONS, SEND_MESSAGE, LOAD_CONVERSATION, ACCOUNT_INFO, GET_CONTRACT, GET_PAYMENTPLAN_PDF,
  PAYMENT_PLAN, BOOKING_LIST, VERIFY_SETUP, RETRIGGER_SETUP, CANCEL_SETUP, CANCEL_ACTIVATION,

  CHANGE_DEFAULT_DUEDAY, CHANGE_ADDRESS, CHANGE_EMAIL, CHANGE_BANK, CHANGE_PASSWORD, DEBIT_MANDATE_INFO,

  LOGIN, INIT_PASSWORD, RESET_PASSWORD, VERIFY_RESET_PASSWORD, RETRIGGER_RESET_PASSWORD, CHECK_RESET_PASSWORD_LINK,
  VERIFY_SETUP_STEP_1, VERIFY_SETUP_STEP_2, RETRIGGER_SETUP_STEP_1, RETRIGGER_SETUP_STEP_2, VERIFY_ADDRESS,
  RETRIGGER_VERIFY_ADDRESS, VERIFY_CHANGE_PASSWORD, RETRIGGER_CHANGE_PASSWORD
}
