import {Injectable} from '@angular/core';
import {
  BasePurchaseInfoRequest,
  CancelActivationRequest,
  ChangePasswordRequest,
  CheckResetPasswordLinkRequest,
  DetailsIdRequest,
  GetAnnuitiesRequest,
  InitPasswordRequest,
  LoginRequest,
  ResetPasswordRequest,
  SecretRequest,
  SupportedCountriesRequest,
  VerificationRequest,
  VerifyResetPasswordRequest
} from '@app/core/backend/requests/request.interfaces';
import {AccountInfoResponse} from '@app/core/backend/response/account-info.response';
import {AnnuitiesResponse} from '@app/core/backend/response/annuities.response';
import {CheckResponse} from '@app/core/backend/response/check.response';
import {LoginResponse} from '@app/core/backend/response/login.response';
import {MandateInfoResponse} from '@app/core/backend/response/mandate-info.response';
import {PartnerShopsResponse} from '@app/core/backend/response/partner-shops.response';
import {PurchaseInfoResponse} from '@app/core/backend/response/purchase-info.response';
import {BasePurchaseInfoResponse} from '@app/core/backend/response/base-purchase-info.response';
import {SupportedCountriesResponse} from '@app/core/backend/response/supported-countries.response';
import {TriggerIdentResponse} from '@app/core/backend/response/trigger-ident.response';
import {AddressInput} from '@app/util/model/address-input';
import {JsonAttachment} from '@app/util/model/json-attachment';
import {PaymentPlan} from '@app/util/model/payment-plan';
import {Terms} from '@app/util/model/terms';
import {Observable} from 'rxjs/index';
import {MessageRequest, ReferralRequest} from '../requests/request.interfaces';
import {StoreDataRequest} from '../requests/store-data.request';
import {BookingListResponse} from '../response/booking-list.response';
import {ConversationDetailResponse} from '../response/conversation-detail.response';
import {ConversationListResponse} from '../response/conversation-list.response';
import {CustomeridResponse} from '../response/customerid.response';
import {SessionInfoResponse} from '../response/session-info.response';
import {StoreDataResponse} from '../response/store-data.response';
import {TokenResponse} from '../response/token.response';
import {TriggerFintecResponse} from '../response/trigger-fintec.response';
import {BackendConnectorService} from './backend-connector.service';
import {RequestType} from './request-type.enum';
import {GetPreferredIdentProviderResponse} from '../response/preferred-ident-provider.response';
import {ResponseInterface} from '@app/core/backend/response/response.interface';
import {SmsVerificationResponse} from '@app/core/backend/response/sms-verification.response';
import {environment} from '@env/environment';


@Injectable()
export class BackendService {

  readonly DEVICE_TYPE = 'WEB';

  readonly CURRENCY = 'EUR';

  readonly PRODUCT_TYPE = environment.productType;

  constructor(private connector: BackendConnectorService) {
  }

  initSession(): Observable<CustomeridResponse> {
    return this.connector.callBackend<any, CustomeridResponse>(RequestType.CREATE_SESSION,
      {deviceType: this.DEVICE_TYPE, productType: this.PRODUCT_TYPE});
  }

  initReferralSession(shortCode: string, key: string): Observable<CustomeridResponse> {
    return this.connector.callBackend<ReferralRequest, CustomeridResponse>(RequestType.URL_REFERRAL_INIT,
      {shortCode: shortCode, key: key, deviceType: this.DEVICE_TYPE});
  }

  storeData(requestDetails: StoreDataRequest): Observable<StoreDataResponse> {
    return this.connector.callBackend<StoreDataRequest, StoreDataResponse>(RequestType.STORE_DATA, requestDetails);
  }

  triggerFintec(): Observable<TriggerFintecResponse> {
    return this.connector.callBackend<any, TriggerFintecResponse>(RequestType.TRIGGER_FINTEC_PRE_ACCOUNT, {});
  }

  accountCheck(): Observable<CheckResponse> {
    return this.connector.callBackend<any, CheckResponse>(RequestType.RISK_CHECK, {});
  }

  login(email: string, password: string): Observable<LoginResponse> {
    return this.connector.callBackend<LoginRequest, LoginResponse>(RequestType.LOGIN,
      {email: email, password: password, productType: this.PRODUCT_TYPE});
  }

  initPassword(password: string): Observable<ResponseInterface> {
    return this.connector.callBackend<InitPasswordRequest, ResponseInterface>(RequestType.INIT_PASSWORD,
      {password: password});
  }

  triggerResetPasswordSmsVerification(request: SecretRequest): Observable<SmsVerificationResponse> {
    return this.connector.callBackend<SecretRequest, SmsVerificationResponse>(RequestType.RETRIGGER_RESET_PASSWORD,
      request);
  }

  triggerChangePasswordSmsVerification(request: DetailsIdRequest): Observable<ResponseInterface> {
    return this.connector.callBackend<DetailsIdRequest, ResponseInterface>(RequestType.RETRIGGER_CHANGE_PASSWORD,
      request);
  }

  checkResetPasswordLink(request: CheckResetPasswordLinkRequest): Observable<ResponseInterface> {
    return this.connector.callBackend<CheckResetPasswordLinkRequest, ResponseInterface>(
      RequestType.CHECK_RESET_PASSWORD_LINK, request);
  }

  verifyNewPassword(request: VerifyResetPasswordRequest): Observable<ResponseInterface> {
    return this.connector.callBackend<VerifyResetPasswordRequest, ResponseInterface>(RequestType.VERIFY_RESET_PASSWORD,
      request);
  }


  verifyPasswordChange(verificationRequest: VerificationRequest): Observable<ResponseInterface> {
    return this.connector.callBackend<VerificationRequest, ResponseInterface>(RequestType.VERIFY_CHANGE_PASSWORD,
      verificationRequest);
  }

  resetPassword(email: string): Observable<ResponseInterface> {
    return this.connector.callBackend<ResetPasswordRequest, ResponseInterface>(RequestType.RESET_PASSWORD,
      {email: email});
  }

  changePassword(newPassword: string): Observable<SmsVerificationResponse> {
    return this.connector.callBackend<ChangePasswordRequest, SmsVerificationResponse>(RequestType.CHANGE_PASSWORD,
      {newPassword: newPassword});
  }

  logout(): Observable<any> {
    return this.connector.callBackend<any, any>(RequestType.LOGOUT, {});
  }

  getVerifiedSessionInfo(): Observable<SessionInfoResponse> {
    return this.connector.callBackend<any, SessionInfoResponse>(RequestType.VERIFIED_SESSION_INFO, {});
  }

  getBookingList(): Observable<BookingListResponse> {
    return this.connector.callBackend<any, BookingListResponse>(RequestType.BOOKING_LIST, {});
  }

  listConversations(): Observable<ConversationListResponse> {
    return this.connector.callBackend<any, ConversationListResponse>(RequestType.LIST_CONVERSATIONS, {});
  }

  loadConversation(id: string): Observable<ConversationDetailResponse> {
    return this.connector.callBackend<any, ConversationDetailResponse>(RequestType.LOAD_CONVERSATION,
      {conversationId: id});
  }

  getAnnuities(): Observable<AnnuitiesResponse> {
    return this.connector.callBackend<any, AnnuitiesResponse>(RequestType.GET_ANNUITIES,
      {});
  }

  getAnnuitiesForAmount(amount: number, apiKey?: string, interestFreeRatesMerchant?: number)
    : Observable<AnnuitiesResponse> {
    return this.connector.callBackend<GetAnnuitiesRequest, AnnuitiesResponse>(RequestType.GET_ANNUITIES_FOR_AMOUNT,
      {
        amount: amount,
        currency: this.CURRENCY,
        apiKey: apiKey,
        productType: this.PRODUCT_TYPE,
        interestFreeRatesMerchant: interestFreeRatesMerchant,
      });
  }

  setPreferredAnnuity(preferredAnnuity: number): Observable<PaymentPlan> {
    return this.connector.callBackend<number, PaymentPlan>(RequestType.SET_PREFERRED_ANNUITY, preferredAnnuity);
  }

  getPaymentPlan(): Observable<PaymentPlan> {
    return this.connector.callBackend<any, PaymentPlan>(RequestType.PAYMENT_PLAN, {});
  }

  replaceToken(): Observable<TokenResponse> {
    return this.connector.callBackend<any, TokenResponse>(RequestType.REPLACE_TOKEN, {});
  }

  triggerIdent(): Observable<TriggerIdentResponse> {
    return this.connector.callBackend<any, TriggerIdentResponse>(RequestType.TRIGGER_IDENT, {});
  }

  resendIdentSms(): Observable<any> {
    return this.connector.callBackend<any, any>(RequestType.RESEND_IDENT_SMS, {});
  }

  getAccountInfo(): Observable<AccountInfoResponse> {
    return this.connector.callBackend<any, AccountInfoResponse>(RequestType.ACCOUNT_INFO, {});
  }

  getPaymentPlanPdf(): Observable<JsonAttachment> {
    return this.connector.callBackend<any, JsonAttachment>(RequestType.GET_PAYMENTPLAN_PDF, {});
  }

  getContract(): Observable<JsonAttachment> {
    return this.connector.callBackend<any, JsonAttachment>(RequestType.GET_CONTRACT, {});
  }

  sendConversation(conversationId: string, subject: string, message: string,
                   attachments?: JsonAttachment[]): Observable<any> {
    return this.connector.callBackend<MessageRequest, any>(RequestType.SEND_MESSAGE,
      {conversationId: conversationId, subject: subject, text: message, attachments: attachments});
  }

  changeAddress(addressInput: AddressInput): Observable<any> {
    return this.connector.callBackend<AddressInput, any>(RequestType.CHANGE_ADDRESS, addressInput);
  }

  verifyAddress(request: VerificationRequest): Observable<any> {
    return this.connector.callBackend<VerificationRequest, any>(RequestType.VERIFY_ADDRESS, request);
  }

  changeEmail(email: string): Observable<any> {
    return this.connector.callBackend<any, any>(RequestType.CHANGE_EMAIL, {newEmail: email});
  }

  changeBank(iban: string): Observable<any> {
    return this.connector.callBackend<any, any>(RequestType.CHANGE_BANK, {iban: iban});
  }

  verifySetup(detailsId: string, code: string) {
    return this.connector.callBackend<any, any>(RequestType.VERIFY_SETUP,
      {detailsId: detailsId, verificationcode: code});
  }

  verifySetupStepOne(request: VerificationRequest) {
    return this.connector
      .callBackend<VerificationRequest, ResponseInterface>(RequestType.VERIFY_SETUP_STEP_1, request);
  }

  verifySetupStepTwo(request: VerificationRequest) {
    return this.connector.callBackend<VerificationRequest, ResponseInterface>(RequestType.VERIFY_SETUP_STEP_2, request);
  }

  cancelSetup(detailsId: string) {
    return this.connector.callBackend<any, any>(RequestType.CANCEL_SETUP,
      {detailsId: detailsId});
  }

  retriggerSetup(detailsId: string) {
    return this.connector.callBackend<any, any>(RequestType.RETRIGGER_SETUP,
      {detailsId: detailsId});
  }

  retriggerAddressVerification(detailsId: string) {
    return this.connector.callBackend<any, any>(RequestType.RETRIGGER_VERIFY_ADDRESS,
      {detailsId: detailsId});
  }

  retriggerSetupStep1(detailsId: string) {
    return this.connector.callBackend<any, any>(RequestType.RETRIGGER_SETUP_STEP_1,
      {detailsId: detailsId});
  }

  retriggerSetupStep2(detailsId: string) {
    return this.connector.callBackend<any, any>(RequestType.RETRIGGER_SETUP_STEP_2,
      {detailsId: detailsId});
  }

  getBasePurchaseInfo(shortCode: string, key: string): Observable<BasePurchaseInfoResponse> {
    return this.connector.callBackend<BasePurchaseInfoRequest, BasePurchaseInfoResponse>(RequestType.BASE_PURCHASE_INFO,
      {shortCode: shortCode, key: key, deviceType: this.DEVICE_TYPE});
  }

  changePreferredAnnuity(preferredAnnuity: number): Observable<any> {
    return this.connector.callBackend<number, any>(RequestType.CHANGE_PREFERRED_ANNUITY,
      preferredAnnuity);
  }

  getDefaultTerms(): Observable<Terms> {
    return this.connector.callBackend<any, Terms>(RequestType.GET_DEFAULT_TERMS,
      {productType: this.PRODUCT_TYPE});
  }

  getDebitMandateInfo(iban: string): Observable<MandateInfoResponse> {
    return this.connector.callBackend<any, MandateInfoResponse>(RequestType.DEBIT_MANDATE_INFO,
      {iban: iban});
  }

  cancelActivation(reason: string): Observable<any> {
    return this.connector.callBackend<CancelActivationRequest, any>(RequestType.CANCEL_ACTIVATION,
      {reason: reason});
  }

  getSupportedCountries(): Observable<SupportedCountriesResponse> {
    return this.connector.callBackend<SupportedCountriesRequest, SupportedCountriesResponse>(
      RequestType.GET_SUPPORTED_COUNTRIES, {brandname: 'FINANCE A BIKE'});
  }

  getAllShops(productType: string): Observable<PartnerShopsResponse> {
    return this.connector.callBackend<string, PartnerShopsResponse>(RequestType.GET_PARTNER_SHOPS, productType);
  }

  checkEmailForTLS(domain: string): Observable<boolean> {
    return this.connector.callBackend<string, boolean>(RequestType.EMAIL_DOMAIN_TLS_CHECK, domain);
  }

  getUnusedUrlInfo(): Observable<PurchaseInfoResponse> {
    return this.connector.callBackend<any, PurchaseInfoResponse>(RequestType.GET_UNUSED_URL_INFO, null);
  }

  selectTransaction(transactionId: string): Observable<any> {
    return this.connector.callBackend<string, any>(RequestType.SELECT_TRANSACTION, transactionId);
  }

  onlineIdentificationGetPreferredProvider(): Observable<GetPreferredIdentProviderResponse> {
    return this.connector.callBackend<any, any>(RequestType.ONLINEIDENTIFICATION_GET_PREFERRED_PROVIDER, null);
  }

}
